<template>
    <div class="accounts">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Accounts</a></li>
            </ul>
        </nav>
        <p class="total-results">{{totalResults}} Results</p>

        <div class="field is-grouped">
            <h1 class="title">Accounts</h1>

            <p class="control mt-1 ml-3">
                <router-link class="button is-primary is-small is-rounded p-3" to="account" :disabled="isLoading">
                    <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                </router-link>
            </p>
        </div>

        <div class="field has-addons mb-0">
            <div class="control is-expanded has-icons-right mr-4">
                <input id="searchBox" ref="searchBox" class="input" type="text" placeholder="Search accounts..."
                       v-model="accountSearch" @keyup.enter="newSearch" autocomplete="off">
                <span v-if="accountSearch" class="icon is-small is-right">
                    <a class="delete" @click="clearSearchTerm"></a>
                </span>
            </div>
            <div class="control mr-4">
                <a class="button is-primary" @click="newSearch" :disabled="isLoading">
                    Search
                </a>
            </div>
            <FilterDropdown :displayAccountStatus="true"
                            :displayAccountIntegrations="true"
                            :filtersApplied=filtersApplied
                            @accountStatusSelected="accountStatusSelected"
                            @accountIntegrationSelected="accountIntegrationSelected"
                            @clearFilters="clearFilters" />
        </div>

        <Loading v-if="isLoading" />
        <div v-else class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th><abbr title="Public Id">PId</abbr></th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Salesforce ID</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(account, index) in accounts" :key="'account' + index">
                        <td> {{ account.id }}</td>
                        <td>
                            <router-link :to="'/account/' + account.publicId">{{ account.publicId }}</router-link>
                        </td>
                        <td>
                            <router-link :to="'/account/' + account.publicId">{{ account.name }}</router-link>
                        </td>
                        <td> {{ account.email }}</td>
                        <td> {{ account.internalSalesforceId }}</td>
                        <td> {{ accountStatusDictionary[account.status] }}</td>
                        <td>
                            <button class="button is-white is-inverted is-small" @click="veryifyDeleteAccount(account)">
                                <font-awesome-icon :icon="['fa', 'trash-alt']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination :isLoading="isLoading" :currentPage="currentPage" :totalPages="totalPages" v-on:setPage="n => setPage(n)" />
    </div>
</template>

<script>
    import { securePost, secureDelete } from '../secureFetch.js';

    import Pagination from '../components/Pagination';
    import Loading from '../components/Loading';
    import FilterDropdown from '../components/FilterDropdown';

    export default {
        name: 'Accounts',
        components: {
            Pagination,
            Loading,
            FilterDropdown
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
          },
          accountSearch: {
            set(value) {
                this.$store.commit('updateAccountSearchFilter', value);
            },
            get() {
                return this.$store.state.accountFilters.searchTerm;
            }
          },
          currentPage: {
            set(value) {
              this.$store.commit('updateAccountPageFilter', value);
            },
            get() {
              return this.$store.state.accountFilters.page;
            }
          },
          accountStatus: {
            set(value) {
              this.$store.commit('updateAccountStatusFilter', value);
            },
            get() {
              return this.$store.state.accountFilters.status;
            }
          },
          accountIntegration: {
            set(value) {
              this.$store.commit('updateAccountIntegrationFilter', value);
            },
            get() {
              return this.$store.state.accountFilters.integration;
            }
          },
          filtersApplied: function () {
            var filters = 0;

            if (this.$store.state.accountFilters.status != null) {
              filters++;
            }

            if (this.$store.state.accountFilters.integration != null) {
              filters++;
            }

            return filters;
          }
        },
        data() {
            return {
                isLoading: true,
                errorMessage: null,
                accounts: [],
                resultsPerPage: 25,
                totalPages: 0,
                totalResults: 0,
                accountIntegrationSelectedName: null,
                accountStatusDictionary: { 0: 'Unknown', 1: 'Active', 2: 'Inactive', 3: 'Deleted' }
            }
        },
        mounted() {
            this.searchAccounts();
        },
        methods: {
            searchAccounts() {
                this.isLoading = true;

                this.accounts = [];
                this.totalPages = 0;
                this.totalResults = 0;
                this.errorMessage = null;

                const integrationTypeCode = this.accountIntegration == 6 ? null : this.accountIntegration;
                const filterByMeevo = this.accountIntegration == 6;

                var postBody = {
                    searchTerm: this.accountSearch,
                    page: this.currentPage,
                    resultsPerPage: this.resultsPerPage,
                    statusTypeCode: this.accountStatus,
                    integrationTypeCode: integrationTypeCode,
                    filterByMeevo: filterByMeevo
                };

                securePost(this.hostUrl + "v1/account/search-accounts", postBody)
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.accounts = data.accounts;
                            this.currentPage = data.currentPage;
                            this.totalPages = data.totalPages;
                            this.totalResults = data.totalResults;
                        }
                        else {
                            this.errorMessage = "Error Retrieving Accounts: " + data.message;
                        }
                    });
            },
            newSearch() {
                this.currentPage = 0;
                this.searchAccounts();
            },
            clearSearchTerm() {
                this.accountSearch = '';
                this.currentPage = 0;
                this.searchAccounts();
                this.$refs.searchBox.focus();
            },
            setPage(page) {
                this.currentPage = page;
                this.searchAccounts();
            },
            accountStatusSelected(status) {
                this.accountStatus = status;
                this.newSearch();
            },
            accountIntegrationSelected(integration) {
                this.accountIntegration = integration.code;
                this.accountIntegrationSelectedName = integration.name;
                this.newSearch();
            },
            clearFilters() {
                this.accountStatus = null;
                this.accountIntegration = null;
                this.accountIntegrationSelectedName = null;
                this.newSearch();
            },
            veryifyDeleteAccount(account) {
                if (confirm("Really delete " + account.name + "?")) {
                    if (confirm("Really really delete " + account.name + "?")) {
                        secureDelete(this.hostUrl + "v1/account/" + account.publicId)
                            .then(data => {
                                this.isLoading = false;

                                if (data && data.success) {
                                    this.searchAccounts();
                                }
                            });
                    }
                }
            }
        }
    };
</script>

<style scoped>
    .total-results {
        float: right;
    }
</style>
